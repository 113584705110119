.ed-wrapper {
    padding: 0 3rem 0 3rem;
    display: flex;
    height: 90vh;
    margin-top: 4rem;
}
.e-left {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
/* right side */
.e-right {
    position: relative;    
}
.e-minCircle {
   position: relative; 
   left: 9rem;
   width: 18rem;
   height: 18rem;
   border-radius: 100%;
   box-shadow: var(--smboxShadow);
   top: 2rem;
   background: white;

}
.e-minCircle > * {
    position: absolute;
}
.e-minCircle > :nth-child(1) {
    top: -3rem;
    left: 6rem;
}
.e-minCircle > :nth-child(2) {
    left: 15rem;
    top: 5rem;
}
.e-minCircle > :nth-child(3) {
    left: 6rem;
    top: 13rem;
}
.e-minCircle > :nth-child(4) {   
    left: -3rem;
    top: 5rem;
}
.e-minCircle > :nth-child(5) {
    transform: scale(0.8);
    left: 6rem;
    top: 5rem;
}

.e-secCircle {
    
    width: 6rem;
    height: 6rem;
    position: absolute;
    border-radius: 100%;
    border: 5px solid #ebebeb;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: none;
    box-shadow: var(--smboxShadow);
    background: white;  
   
}
.image-label{
    font-size: 14px;
    color: var(--gray);
}
.e-minCircle > img {
    transform: scale(0.6);  
}
.e-minCircle > :nth-child(5) >img {
    transform: scale(0.5);
}
.e-backCircle {
    position: absolute;
    height: 13rem;
    width: 13rem;
    border-radius: 100%;
    z-index: -1;
}
.blueCircle {
    background: #1949B8;
    left: 18rem;
    top: 0rem;
}
.yellowCircle {
    background: #F5C32C;
    left: 18rem;
    top: 8rem;
}

@media screen and (max-width: 480px) {
    .ed-wrapper {
        margin-top: 0;
        flex-direction: column;
        gap: 1rem;
        height: 66rem;
        padding: 0;
        position: relative;
    }
    .e-right {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        transform: scale(.5);
        left: 0!important;
        position: static;      
       
    }
    .e-mainCircle>* {
        position: static;
    }
    .e-backCircle {
       display: none;
    }
    .e-right>* {
        position: static;
    }
}

