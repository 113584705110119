.p-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 70vh;
  

}
/* heading */
.p-center {
  display: flex;
  flex-direction: column; 
  align-items: center;
}

.r-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.6rem;
  padding: 1rem;
  border-radius: 10px;
  max-width: max-content;
  margin: auto;
  transition: all 300ms ease-in;
  margin-top: 1rem;
}
.r-card>img {
width: 100%;
max-width: 15rem;

}

.r-card>:nth-child(2) {
font-size: 1rem;
font-weight: 600;
}

.r-card>:nth-child(3) {
font-size: 1rem!important;
font-weight: 500;
}

.r-card>:nth-child(4) {
font-size: 0.7rem;
width: 80%;

}



.r-card:hover {
scale: 1.025;  
background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(136, 160, 255, 0.46) 217.91%
);
box-shadow: 0px 72px 49px -51px rgba(136, 160, 255, 0.21);
}

.swiper-horizontal {
overflow: visible;
}

.r-buttons {
/* position: absolute; */
display: flex;
row-gap: 2rem;
justify-content: center;
align-items: center;
flex-wrap: wrap;
gap: 1rem;
top: -4rem;
right: 0;

}
.r-buttons button {
font-size: .8rem;
padding: 0.2rem 0.8rem;
color: blue;
border: none;
border-radius: 5px;
background-color: rgb(236, 229, 229);
cursor: pointer;
margin-top: 1.5rem;
margin-bottom: 4rem;
}

.source {
  text-align: center;
}

@media screen and (min-width: 756px){
  .r-card {

    max-width: 12rem!important;
  }
}