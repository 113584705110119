.f-wrapper {
    display: flex;
    align-items: center;
    margin: -0.5rem -3.5rem;
    position: relative;
    margin-top: -7rem;
}

.f-content{
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 4rem;
    gap: 3rem;
    color: white;
}
.f-icons {
    display: flex;
    gap: 2rem;
   
}

/* Media query for screens with a maximum width of 480px */
@media screen and (max-width: 480px){
    .f-content{
      transform: scale(.5);
 
    }
  }
  
  
  
  
  
  
  
  