.floatingdiv {
    display: flex;    
    justify-content: space-around;
    background: rgb(197, 177, 196);
    box-shadow: var(--boxShadow);
    border-radius: 17px;
    align-items: center;
    padding: 0px 28px 0px 0px;
    height: 4.5rem;
}

.floatingdiv>img {
    transform: scale(0.4);
}
.floatingdiv>span {
    font-family: sans-serif;
    font-size: 16px;
}

.blur {
    position: absolute;
    width: 22rem;
    height: 14rem;
    border-radius: 50%;
    background: #edd0ff;
    filter: blur(72px);
    z-index: -9;
    top: -18%;
    left: 56%;
}