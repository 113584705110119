.i-wrapper {
    display: flex;
    height: 77vh;
    margin-top: 6rem;
}

.i-left {
    display: flex;
    position: relative;
    flex: 1;
    flex-direction: column;  
    gap: 2rem; 
    justify-content: flex-start; 
}
.i-name {
    display: flex;
    flex-direction: column;
}

/* .buttons {
    display: flex;
    
} */

.i-button {
    width: 7.5rem;
    height: 2.1rem;
    margin-left: 0;
}

.i-icons {
  display: flex;
   gap: 0rem; 
}
.i-icons>*{
    transform: scale(0.5);
}
.i-icons>*{
    cursor: pointer;    
}
/* Right side */
.i-right {
    flex: 1 ;
    position: relative; 
    
}
.i-right>* {
    position: absolute;
    z-index: 1;
}
.i-right>:nth-child(1) {
    transform: scale(0.69);
    left: -15%;
    top: -9%;
}
.i-right>:nth-child(2) {
    top: -4.6rem;
    transform: scale(0.67);
    left: -3rem;
}
.i-right>:nth-child(3) {
    transform: scale(.7);
    left: 9%;
    bottom: 30%;
}

@media screen and (max-width: 480px) {
    .i-wrapper {
      flex-direction: column;
      height: 72rem;
      gap: 7rem;
    }
    .i-right {
        transform: scale(0.8);
        left: -3rem;
        margin-top: 1rem;
    }
    .i-right .blur {
        display: none;
    }
    .FloatingDiv:nth-of-type(1){
        top: -7rem !important;
    }
   
    .i-right>:nth-child(3) {
        transform: scale(1.1);
        left: 15%;
        bottom: 45%;
        /* top: -10%; */
    }
   
  }
