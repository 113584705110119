.contact-wrapper {
    padding: 0 3rem 0 3rem;
    display: flex;
    height: 80vh;
    margin-top: 10rem;
}

.c-right {
    display: flex;
    justify-content: center;
    position: relative;
    flex: 1;
}

.c-right>form {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
}
.c-right .user {
    width: 20rem;
    height: 2rem;
    padding: 0.3em;
    outline: none;
    border: 2px solid var(--orange);
    border-radius: 8px;
    font-size: 16px;

}
textarea {
    height: 4rem!important;
}
.c-blur1 {
    top: 1rem;
    left: 8rem;
}

.c-left {
    display: flex;
    flex-direction: column;    

} 
@media screen and (max-width: 480px) {
    .contact-wrapper {
      flex-direction: column;
      height: auto;
      padding: 2rem;
      margin-top: 5rem;
    }
  
    .c-left {
      text-align: center;
    }
  
    .c-right {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .c-right form {
      max-width: 100%; /* Adjust the maximum form width as needed */
    }
  
    .user {
      width: 100%; /* Make input fields take full width */
      max-width: 100%; /* Adjust maximum input width as needed */
    }
  
    textarea {
      height: auto; /* Remove fixed textarea height */
    }
  
    .button {
      width: 100%; /* Make the button take full width */
      margin-bottom: 5rem;
    }
  
    .c-blur1 {
      top: 0;
      left: 0;
    }
  }
  